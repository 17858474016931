<template>
  <div>
    <div class="my-5 pt-sm-1">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="mb-5 text-center">
              <img src="@/assets/images/logo.svg" alt="logo" height="50" />
            </div>

            <div class="container">
              <div class="row">
                <div class="col-sm-10 offset-sm-1 col-xs-12">
                  <div class="card">
                    <div class="card-body">
                      <!--  -->
                      <div v-for="(survey, index) in questions" :key="index">
                        <strong class="d-block mt-2 mb-1">
                          {{ index + 1 }}.
                          {{ questions[index].question }}
                        </strong>
                        <div class="d-flex">
                          <div class="col">
                            <!-- Type 1 -->
                            <div
                              class="form-group"
                              v-if="questions[index].type == 1"
                            >
                              <div
                                v-for="(option, optionIndex) in questions[index]
                                  .options"
                                :key="optionIndex"
                              >
                                <div class="preview-option-wrapper">
                                  <input
                                    type="radio"
                                    class="form-check-input"
                                    v-model="questions[index].answer"
                                    :name="index"
                                    :value="
                                      questions[index].options[optionIndex]
                                    "
                                    :id="'o' + index + optionIndex"
                                  />
                                  <label :for="'o' + index + optionIndex">
                                    {{ questions[index].options[optionIndex] }}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Type 1 End  -->
                            <!-- Type 2 -->
                            <div v-if="questions[index].type == 2">
                              <div class="form-group">
                                <input
                                  type="text"
                                  for="text"
                                  class="form-control"
                                  placeholder="Type here"
                                  v-model="questions[index].answer"
                                />
                              </div>
                            </div>
                            <!-- Type 2 End -->
                            <!-- Type 3 -->
                            <div v-if="questions[index].type == 3">
                              <div class="row">
                                <div class="col-12">
                                  Please drag and drop to the right side for the
                                  sort you want.
                                </div>
                                <div class="col">
                                  <draggable
                                    class="dragArea list-group"
                                    :list="questions[index].options"
                                    group="order"
                                  >
                                    <div
                                      class="list-group-item"
                                      v-for="(element, lindex) in questions[
                                        index
                                      ].options"
                                      :key="lindex"
                                    >
                                      <i class="ri-list-unordered"></i>
                                      {{ element }}
                                    </div>
                                  </draggable>
                                </div>
                                <div class="col">
                                  <draggable
                                    class="dragArea list-group right-side"
                                    :list="questions[index].answer"
                                    group="order"
                                  >
                                    <div
                                      class="list-group-item"
                                      v-for="(element, lindex) in questions[
                                        index
                                      ].answer"
                                      :key="lindex"
                                    >
                                      {{ lindex + 1 }}.
                                      {{ element }}
                                    </div>
                                  </draggable>
                                </div>
                              </div>
                            </div>
                            <!-- Type 3 End-->
                          </div>
                        </div>
                      </div>
                      <!--  -->
                      <div class="col-12 text-center">
                        <button
                          type="button"
                          class="btn btn-success"
                          @click="saveAnswer"
                          v-if="questions.length > 0"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/main-source";
import draggable from "vuedraggable";
import Swal from "sweetalert2";

export default {
  name: "answer",
  data() {
    return { questions: [], controlOnStart: true };
  },
  created() {
    this.getData();
  },
  components: {
    draggable,
  },
  methods: {
    getData() {
      HTTP.get("public/surveys/" + this.$route.params.shortlink).then(
        (result) => {
          if (result.data[0].answers) {
            Swal.fire({
              icon: "error",
              text: "This link expired. Redirecting...",
              timer: 4000,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              window.location = "https://galifopartners.com/";
            }, 4000);
          } else {
            let questions = JSON.parse(result.data[0].survey.questions);
            this.questions = questions.map((question) => {
              question.type == 3
                ? (question.answer = [])
                : (question.answer = null);
              return question;
            });
          }
        }
      );
    },
    add: function () {
      this.list.push({ name: "" });
    },
    replace: function () {
      this.list = [{ name: "" }];
    },
    clone: function (el) {
      return {
        name: el.name + " cloned",
      };
    },
    saveAnswer() {
      HTTP.put(
        "public/surveys/" + this.$route.params.shortlink,
        this.questions
      ).then(() => {
        Swal.fire({
          icon: "success",
          text: "Answers successfuly saved! Thank you!",
          timer: 4000,
          showCancelButton: false,
          showConfirmButton: false,
        });
        setTimeout(() => {
          window.location = "https://galifopartners.com/";
        }, 4000);
      });
    },
  },
};
</script>